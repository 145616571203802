html {
    height: 100%;
}
body {
    min-height: 100%;
}

body{
    padding:0px;
    margin:0px;
}

.test{
    width:100%;
    height:100%;
}